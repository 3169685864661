.Landing {
    font-family: 'AlwynNewRounded-Light', sans-serif;
    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 500px;
}
.Landing-wrapper{

    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    margin-right: 0;
    padding-right: 0;
    flex-direction: row;

}

.Landing-content-wrapper{
    width: 100%;
    text-align: left;
    margin-right: 0;
    padding-right: 0;
}

.Landing-text{
    height: 40%;
    width: 70%;
    margin-left: 50px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.Landing-segment{
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    flex-direction: column;



}

.Landing-self-text{
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
}

.Landing-image{
    margin: 0;
    padding: 0;
    margin-right: 2rem;
    align-content: flex-end;
    justify-content: flex-end;
}

.Landing-self-text p{
    font-size: 25px;
    font-family: "AlwynNewRounded-Light", sans-serif;
 }

 .Landing-title{
    font-family: "AlwynNewRounded-Bold", sans-serif;
    font-size: 45px;
    margin-bottom: 25px;
    font-style: bold;
 }

 .saiba-mais p{
     font-size: 15px;
     color: #4183c4;
 }

 .ouro {
    color: #fec514;
 }

 .prata {
    color: #686868;
 }

 .bronze {
    color: #d47727;
 }

 .link {
     color: #4183c4;
     text-decoration: underline;
 }
