@font-face {
  font-family: 'AlwynNewRounded-Light';
  font-style: normal;
  font-weight: 400;
  src: local('AlwynNewRounded-Light'),
    url('./fonts/AlwynNewRounded-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'AlwynNewRounded-Bold';
  font-style: normal;
  font-weight: 400;
  src: local('AlwynNewRounded-Bold'),
    url('./fonts/AlwynNewRounded-Bold.ttf') format('truetype');
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ui.header{
  font-family: AlwynNewRounded-Bold;
}

.ui.segment{
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

@media only screen and (max-width: 767px)
  {
  .ui.container {
      width: auto!important;
      margin-left: 0!important;
      margin-right: 0!important;
  }
}