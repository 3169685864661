#accordion-item {
    margin: 10px 60px 10px 60px;
}

.FAQ-Title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    
    margin-top: 10px;
   
   
}