.Calendar {
    width: 100%;
    height: 100%;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.Calendar-title{
    display: flex;
    margin-bottom: 10px;
    margin-top: 50px;
}


#Calendar-cards {
    padding-top: 5px;
    width: 100%;
    font-family: "AlwynNewRounded-Light", sans-serif;
    text-align: center;
}


