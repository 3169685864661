.Purpose-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10%;
    margin-right: 10%;
}

.Purpose-title{
    display: flex; 
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    
}

.Purpose-self-text{
    display: flex;
    text-align: justify;
   
}

.A{
    border: thin solid black;
}