.Columns{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    width: 100%;
    padding-left: 10px;
    margin-left: 10px;
    color: white;

    
}

h3 {
    font-family: "AlwynNewRounded-Bold", sans-serif;
}

.Columns-list{

    
}