.Footer{
    padding-top: 50px;
    background-color: #212121;
    padding-bottom: 25px;
}

.Footer-Grid-1{
    margin-bottom: 100px;
}

.Grid-row1{
    height: 100%; 
    margin-left: 10px;
    
}

.Grid-row2{
    height: 30%;
    display: flex;
    flex-wrap: wrap;
}

.Footer-pet-logo{
    width: 100%;
    height: 100%;
    
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;

}

.Footer-pet-rights{
    width: 100%;
    height: 100%;
    color: white;
    text-align: center;
    
    display: flex;
    align-items: center;
    justify-content: center;
      
}

.Footer-pet-social{
    width: 100%;
    height: 100%;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
   
}

.Footer-contato{
    display: flex;
    justify-content: center;
    width: 40%;
}

.Footer-info{
    display: flex;
    width: 30%;

   
}

.Footer-top{
    margin-bottom: 50px;
    margin-left: 100px;   
   
}

.Fotter-top-mobile{
    margin-bottom: 50px;
   
}

.Footer-contact-mobile{
    color: white;
    font-family: "AlwynNewRounded-Light", sans-serif;
    padding-left: 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    

   
}


.Footer-contact{
    color: white;
    font-family: "AlwynNewRounded-Light", sans-serif;
    padding-left: 30px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    

   
}

.Footer-contact-title{
    width: 100%;
    
   
    margin-bottom: 25px;
}


.Footer-contact h3 {
    padding-top: 0;
    margin-top: 0;
}

.Footer-mobile{
    height: 50px;
}