.Navbar {
    width: 100%;
    padding-left: 55px;
    padding-right: 30px;

}

#Menu-items {
    font-family: "AlwynNewRounded-Light", sans-serif;
    display: flex;
    align-items: center;
    padding: 0px 20px 0px 20px;
}

a, link.item .Menu-items{
    color: rgba(0,0,0,.6);
    font-weight: normal;
}

.ui.menu .ui.dropdown .menu>.item, .sidebar.menu>.item {
    color: rgba(0,0,0,.6) !important;
}

a:hover{
    color: rgba(0,0,0,.87);
}