.List{
    height: 50%;
    padding-left: 10px;

    display: flex;
    flex-direction: column;

}
.LinksList-item{
    color: white;
}
ul {
    list-style-type: none;
    padding-left: 0;
}

li {
    margin-bottom: 20px; 
    font-family: "AlwynNewRounded-Light", sans-serif;
}

ul a {
    color: white;
    text-decoration: none;
}