.ui.card>.content>.header {
    display: flex;
    justify-content: center;
}

.Modality-title{
    width: 100%;
    display: flex; 
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.Modality-wrapper{
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
}

.Modality-cards-wrapper{
    display: flex;
    flex-direction: row wrap;
    align-content: center;

    width: 100%;
}